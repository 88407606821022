













































@import "./src/assets/css/project-variables";

.danger{
  color: $--color-primary;
}
.success{
  color: $--color-success;
}
